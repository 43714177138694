let lastKnownScrollPosition = 0;
let previousValue = 0;
let ticking = false;
const footerBlurb = document.getElementById('footerBlurb');
footerBlurb.innerText = `${footerBlurb.innerText} ${new Date().getFullYear()}`;

const header = document.getElementById('header');
function onScroll(scrollPos) {
    if (scrollPos <= 0 && previousValue > 0) {
        header.classList.remove('sticky');
    }

    if (scrollPos > 0 && previousValue <= 0) {
        header.classList.add('sticky');
    }

    previousValue = scrollPos;
}

document.addEventListener('scroll', function (e) {
    lastKnownScrollPosition = window.scrollY;

    if (!ticking) {
        window.requestAnimationFrame(function () {
            onScroll(lastKnownScrollPosition);
            ticking = false;
        });

        ticking = true;
    }
});